import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { handlePagination } from '@src/redux/helpers';
import api from '@src/utility/api';

export const fetchAll = createAsyncThunk(
    'emailRequests/fetchAll',
    async (params) => {
        const response = await api.post("/api/email/search", params)
        return response.data;
    }
);

export const fetchById = createAsyncThunk(
    'emailRequests/fetchById',
    async (id) => {
        const response = await api.get(`/api/email/${id}`)
        return response.data;
    }
);


export const create = createAsyncThunk(
    'emailRequests/create',
    async (payload) => {
        const response = await api.post(`/api/email`, payload)
        return response.data;
    }
);





const emailSlice = createSlice({
    name: 'emailRequests',
    initialState: {
        items: [],
        loading: false,
        error: null,
        errors: null,
        selectedItem: {},
        openDetailsModal: false,
    },
    reducers: {
        openDetailsModal: (state, action) => {
            state.openDetailsModal = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.fulfilled, (state, action) => {
                handlePagination(state, action.payload)
            })
            .addCase(fetchById.fulfilled, (state, action) => {
                state.selectedItem = action.payload;
                const { successCallBack } = action.meta.arg;
                successCallBack && successCallBack(action.payload)
            })
    },
});

export const { openDetailsModal } = emailSlice.actions;
export default emailSlice.reducer;

