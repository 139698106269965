// ** Reducers Imports
import layout from "./layout";
import navbar from "./navbar";

import auth from './authentication'
import lookups from './lookups'
import smsRequests from './sms'
import emailRequests from './email'
import campaign from './campaign'
import recipent from "./recipent";
import provider from "./providers";
import sender from "./senders";
import client from "./clients";
import channel from "./channel";
import setting from "./setting"
import whatsappRequests from "./whatsapp"
import template from "./template"

import dataTables from '@src/views/tables/data-tables/store'
import { templateApi } from "./templateApi";
import tags, { tagApi } from "./tagApi";

const rootReducer = { 
    navbar, 
    layout,
    auth,
    smsRequests,
    emailRequests,
    lookups,
    campaign,
    recipent,
    provider,
    sender,
    client,
    channel,
    setting,
    whatsappRequests,
    template,
    "templateApi":templateApi.reducer,
    "tagApi":tagApi.reducer,
    tags
};

export default rootReducer;
