import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './api';
import { createSlice } from '@reduxjs/toolkit';


export const tagApi = createApi({
    reducerPath: 'tagApi',
    baseQuery: axiosBaseQuery(),
    endpoints(build) {
        return {
            getTagsKeys: build.query({
                query: () => ({ url: `/api/tags/keys`, method: 'get' })
            }),
            searchTags: build.mutation({
                query: (values) => ({ url: `/api/tags/search`, data: values, method: 'post' })
            })
        };
    },
});




const tagsSlice = createSlice({
    name: 'tags',
    initialState: {
        filteredTags: [],
        emailIds: [],
        whatsAppIds: [],
        smsIds: [],
        linkIds: []
    },
    reducers: {
        setFilteredTags: (state, action) => {
            state.filteredTags = action.payload.tags;
            state.emailIds = action.payload.emailIds;
            state.whatsAppIds = action.payload.whatsAppIds;
            state.smsIds = action.payload.smsIds;
            state.linkIds = action.payload.linkIds;
        },
        clearFilteredTags: (state) => {
            state.filteredTags = [];
            state.emailIds = [];
            state.whatsAppIds = [];
            state.smsIds = [];
            state.linkIds = [];
        }
    },

});


export default tagsSlice.reducer;

export const { setFilteredTags, clearFilteredTags } = tagsSlice.actions;
export const { useGetTagsKeysQuery, useSearchTagsMutation } = tagApi