// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-multiselect-panel {
  z-index: 20000 !important;
}

.limit-text {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.actions-inline-spacing {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.actions-inline-spacing > * {
  margin-right: 0.15rem;
  margin-top: 0.15rem;
}

.error-message {
  color: red;
  font-family: Arial, sans-serif;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 10px;
  border-radius: 5px;
  margin: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAGA;EAAqB,yBAAA;AADrB;;AAGA;EACC,qBAAA;EACG,YAAA;EACA,mBAAA;EACA,2BAAA;EACA,uBAAA;AAAJ;;AAEA;EACI,aAAA;EACA,eAAA;EACA,2BAAA;EACA,mBAAA;AACJ;AAAI;EACE,qBAAA;EACA,mBAAA;AAEN;;AAEE;EACE,UAAA;EACA,8BAAA;EACA,yBAAA;EACA,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,cAAA;AACJ","sourcesContent":["// @import '../node_modules/prismjs/themes/prism-tomorrow';\n// @import './assets/scss/app.scss';\n\n.p-multiselect-panel{z-index:20000!Important;}\n\n.limit-text{\n\tdisplay: inline-block;\n    width: 180px;\n    white-space: nowrap;\n    overflow: hidden !important;\n    text-overflow: ellipsis;\n}\n.actions-inline-spacing {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: flex-start;\n    align-items: center;\n    & > * {\n      margin-right: 0.15rem;\n      margin-top: 0.15rem;\n    }\n  }\n\n  .error-message {\n    color: red;\n    font-family: Arial, sans-serif;\n    background-color: #f8d7da;\n    border: 1px solid #f5c6cb;\n    padding: 10px;\n    border-radius: 5px;\n    margin: 10px 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
